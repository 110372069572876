import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Workflow that just works',
    paragraph: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum — semper quis lectus nulla at volutpat diam ut venenatis.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container center-content " style={{marginTop:-50}}>
        <div className={innerClasses}>
          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
          <div className={splitClasses} style={{marginTop:-50}}>
{/* user experience */}
            <div className="split-item" width={896} style={{marginTop:-50}}>
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                User experience
                </div>
                <h3 className="mt-0 mb-12">
                Elegant. Simple. Fast
                </h3>
                <p className="m-0">
                The app is made to work smoothly on all devices without taking much space. Fast performance, hi-resolution images, delightful icons and animations are present throughout the app to enhance the user experience.
                </p>

              </div>

              <div
                className={
                  classNames(
                    'split-item-content center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                  )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/home.webp')}
                  width={350}
                  height={619} />

              </div>
            </div>
{/* dark theme */}
            <div className="split-item" width={896}  style={{marginTop:-50}}>
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Night Mode
                </div>
                <h3 className="mt-0 mb-12">
                  Dark Theme
                </h3>
                <p className="m-0">
                Dark Theme interface helps to reduce eye strain during night and reduces battery usage. With night mode, you can have a pleasant experience while using the app for longer durations.
                </p>
              </div>
              <div
                className={
                  classNames(
                    ' center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                  )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/darktheme.webp')}
               
                  width={350}
                  height={619} />

              </div>
              
            </div>
{/* comment */}
<div className="split-item" width={896} style={{marginTop:-50}}>
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Comments
                </div>
                <h3 className="mt-0 mb-12">
                Read User Comments
                </h3>
                <p className="m-0">
                You can expand, collapse the comments with simple gestures. The app gives you all the options to upvote, downvote, reply, save the comments. With a cutom made UI, it is made easy to read the comments and navigate between them.
                </p>

              </div>

              <div
                className={
                  classNames(
                    'split-item-content center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                  )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/comment.webp')}
                  width={350}
                  height={619} />

              </div>
            </div>
           {/* search */}
           <div className="split-item" width={896}style={{marginTop:-50}}>
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Comprehensive search
                </div>
                <h3 className="mt-0 mb-12">
                Search Anything
                </h3>
                <p className="m-0">
                With the powerful search feature, you can easily search subreddits, users and posts. The search comes with multiple sort options, custom filters and an intuitive interface.

                </p>
              </div>
              <div
                className={
                  classNames(
                    ' center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                  )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/search.webp')}
               
                  width={350}
                  height={619} />

              </div>
              
            </div>
{/*setting */}
<div className="split-item" width={896}style={{marginTop:-50}}>
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Personalization
                </div>
                <h3 className="mt-0 mb-12">
                Custom Settings
                </h3>
                <p className="m-0">
                The app provides various customizations to give you a personalized user experience. Settings include, Wi-Fi & Mobile data saver, auto play of videos, switching between card and list views, multiple sort options, hiding/showing flairs etc
                </p>

              </div>

              <div
                className={
                  classNames(
                    'split-item-content center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                  )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/settings.webp')}
                  width={350}
                  height={619} />

              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;